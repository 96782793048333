import React from 'react'

import AccordionList from 'components/accordionList'

export default ({
    heading,
    copy,
    accordionItems,
}) => {
    const columns = accordionItems.map(item => {
        return {
            question: item.heading,
            answer: item.copy
        }
    })

    return (
        <div className="container">
            <div className="grid">
                <div className="column">
                    <h2>
                        {heading}
                    </h2>
                    <p>
                        {copy}
                    </p>
                </div>
                <div className="column">
                    <AccordionList
                        columns={[columns]}
                    />
                </div>
            </div>
        </div>
    )
}