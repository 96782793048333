import React, { useEffect, useRef, useState } from 'react'
import { isEqual, throttle } from 'lodash'

import Hero from 'components/hero'
import LogoGrid from 'components/logoGrid'
import TitleCopy from 'components/titleCopy'
import FeatureInteractive from 'components/featureInteractive'
import BigTiles from 'components/bigTiles'
import CopyImage from 'components/copyImage'
import LatestBlog from 'components/latestBlog'
import BlogGrid from 'components/flexibleBlogGrid'
import EnvironmentsShowcase from 'components/environmentsShowcase'
import ContactImageGrid from 'components/contactImageGrid'
import QuotesCarousel from 'components/quotesCarousel'
import FooterHero from 'components/footerHero'
import Faqs from 'components/faqs'
import ProductOverview from 'components/productOverview'
import ProductExplorer from 'components/productExplorer'
import Accordion from 'components/accordion'
import Cta from 'components/cta'
import Video from 'components/video'
import BigCta from 'components/bigCta'
import ImageAndCopyWithLogos from 'components/imageAndCopyWithLogos'
import TwoColumnImageAndText from 'components/twoColumnImageAndText'
import CopyColumns from 'components/copyColumns'
import Subhero from 'components/subhero'
import FormSection from 'components/formSection'
import StoriesGrid from 'components/storiesGrid'
import BookingCta from 'components/bookingCta'
import TeamMembers from 'components/teamMembers'
import Testimonials from 'components/testimonials'
import Stats from 'components/stats'
import EnvironmentsGrid from 'components/environmentsGrid'

const components = {
    Hero,
    LogoGrid,
    TitleCopy,
    FeatureInteractive,
    BigTiles,
    CopyImage,
    LatestBlog,
    BlogGrid,
    EnvironmentsShowcase,
    ContactImageGrid,
    QuotesCarousel,
    FooterHero,
    Faqs,
    ProductOverview,
    ProductExplorer,
    Accordion,
    Cta,
    Video,
    BigCta,
    ImageAndCopyWithLogos,
    TwoColumnImageAndText,
    CopyColumns,
    Subhero,
    FormSection,
    StoriesGrid,
    BookingCta,
    TeamMembers,
    Testimonials,
    Stats,
    EnvironmentsGrid,
}

export default ({ 
    content,
    slug = '',
    location,
}) => {
    const componentRefs = useRef([])
    const [visibleIndices, setVisibleIndices] = useState([])

    const visibleIndicesRef = useRef(visibleIndices)

    visibleIndicesRef.current = visibleIndices

    const handleScroll = () => {
        let _visibleIndices = []

        componentRefs.current.forEach((componentRef, index) => {
            const offset = componentRef.getBoundingClientRect().top

            let windowHeight = null
            
            if (typeof window != 'undefined') {
                windowHeight = window.innerHeight
            }

            if(offset < (windowHeight / 3 * 2)) {
                _visibleIndices.push(index) 
            }
        })

        if(!isEqual(_visibleIndices,visibleIndicesRef.current)) {
            setVisibleIndices(_visibleIndices)
        } 
    }

    const debouncedHandleScroll = useRef(throttle(handleScroll, 100))

    useEffect(() => {
        const { current } = debouncedHandleScroll

        window.addEventListener('scroll', current)

        setTimeout(() => {
            current()
        },500)

        return () => window.removeEventListener('scroll', current)
    }, [])

    const addComponent = (ref, index) => {
        if(ref){
            const _componentRefs =  [...componentRefs.current]

            _componentRefs[index] = ref

            componentRefs.current = _componentRefs
        }
    }

    return (
        <div className={slug}>
            { content.flexibleContent.map((row, index) => {
                if(!row){
                    return null
                }

                const Component = row.__typename.replace('WPGraphQL_Page_Flexiblecontent_FlexibleContent_','')
                const Tag = components[Component]

                const isScrolledIntoView = visibleIndices && visibleIndices.indexOf(index) !== -1

                let className = `${Component} ${ isScrolledIntoView ? 'scrolled-into-view' : '' }`

                return (
                    <section 
                        className={`${className} ${row.hide === true ? 'hidden' : ''}`} 
                        ref={ref => addComponent(ref, index)} 
                        key={index}
                    >
                        <Tag 
                            {...row} 
                            isScrolledIntoView={isScrolledIntoView}
                            location={location}
                        />
                    </section>
                )
            }) }
        </div>
    )
}

