import React from "react"
import { graphql } from "gatsby"


import SEO from "components/seo"
import FlexibleContent from "components/flexibleContent"

export default ({
  data,
  location,
}) => {
	const { 
		databaseId, 
    title, 
    slug,
		excerpt, 
		flexibleContent 
  } = data.siteFields.page

	return (
		<>
			<SEO title={title} description={excerpt} />
      <FlexibleContent 
        id={databaseId} 
        content={flexibleContent} 
        slug={slug}
        location={location}
      />
		</>
	)
}

export const query = graphql`
  query($databaseId: ID!) {
    siteFields {
      page(id: $databaseId, idType: DATABASE_ID) {
        databaseId
        title
        slug
        date
        content(format: RENDERED)
        featuredImage {
          altText
          title(format: RENDERED)
          mediaItemUrl
          slug
        }
        flexibleContent {
          flexibleContent {
            __typename
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_Hero {
              backgroundVideo {
                sourceUrl
                mediaItemUrl
              }
              popUpVideo {
                sourceUrl
                mediaItemUrl
              }
              backgroundColour
              backgroundImage {
                sourceUrl
              }
              backgroundImages {
                sourceUrl
              }
              button {
                title
                url
              }
              featureImage {
                sourceUrl
              }
              title
              subtitle
              alignment
              height
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_LogoGrid {
              title
              greyOutLogos
              logos {
                image {
                    mediaItemUrl
                }
                url
                label
              }
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_TitleCopy {
              title
              copy
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_FeatureInteractive {
              points {
                title
                copy
                icon {
                  sourceUrl
                }
                background {
                  sourceUrl
                }
              }
              headset {
                transparent {
                  mediaItemUrl
                }
                normal {
                  mediaItemUrl
                }
              }
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_BigTiles {
              title
              tiles {
                title
                copy
                centreContent
                backgroundImage {
                  sourceUrl
                }
                linkedPage {
                  ... on WPGraphQL_Page {
                    slug
                    link
                  }
                  ... on WPGraphQL_Product {
                    slug
                    link
                  }
                }
              } 
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_CopyImage {
              title
              copy
              featureImage {
                sourceUrl
              }
              dontGhostFeatureImage
              overlapFollowingComponent
              button {
                title
                url
              }
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_LatestBlog {
              title
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_ProductExplorer {
              heading
              items {
                icon {
                  sourceUrl
                }
                label
              }
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_QuotesCarousel {
              copy
              quotes {
                image {
                  sourceUrl
                }
                copy
                attribution
              }
              hideComponent
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_EnvironmentsShowcase {
              heading 
              copy
              button {
                title
                url
              }
              slides {
                title 
                copy 
                video {
                  mediaItemUrl
                }
                image {
                  sourceUrl
                  mediaDetails {
                    sizes {
                      name
                      sourceUrl
                    }
                  }
                }
              }
              isTabs
              tabs {
                tabName
                slides {
                  title 
                  copy 
                  video {
                    mediaItemUrl
                  }
                  image {
                    sourceUrl
                    mediaDetails {
                      sizes {
                        name
                        sourceUrl
                      }
                    }
                  }
                }
              }
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_FooterHero {
              heading
              copy
              image {
                sourceUrl
              }
              facebookLink 
              youtubeLink
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_ContactImageGrid {
              heading
              copy
              button {
                title  
                url 
              }
              gridItems {
                label 
                image {
                  sourceUrl
                }
              }
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_Faqs {
              heading 
              categories {
                name
                icon {
                  sourceUrl
                }
                questions {
                  question
                  answer
                }
              }  
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_ProductOverview {
              heading
              copy
              downloadTabs {
                tabName
                files {
                  file {
                    mediaItemUrl
                  }
                  externalLink
                  title
                  alternativeLabel
                }
              }
              features {
                icon {
                  sourceUrl
                }
                heading
                copy
              }
              diagramImage {
                sourceUrl
              }
              diagramFeatures {
                copy
                desktop {
                  alignment
                  xOffset
                  yOffset
                  lineLength
                  linePlacement
                }
                mobile {
                  sameAsDesktop
                  alignment
                  xOffset
                  yOffset
                  lineLength
                  linePlacement
                }
              }
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_ProductExplorer {
              heading
              items {
                icon {
                  sourceUrl
                }
                label
              }
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_Accordion {
              heading
              copy
              accordionItems {
                heading
                copy
              }
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_Cta {
              heading 
              buttonLabel 
              buttonUrl 
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_Video {
              heading
              textAlign
              backgroundImage {
                sourceUrl
              }
              video {
                mediaItemUrl
              }
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_BigCta {
              heading
              button {
                title 
                url
              }
              backgroundImage {
                mediaItemUrl
              }
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_BlogGrid {
              title
              category {
                ... on WPGraphQL_Category {
                  databaseId
                  slug
                }
              }
              postType
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_Subhero {
              copy
              button {
                title
                url
              }
              alignment
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_CopyColumns {
              backgroundColour
              columns {
                heading 
                copy
              }
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_TwoColumnImageAndText {
              image {
                mediaItemUrl
              }
              button {
                title
                url
              }
              video {
                mediaItemUrl
              }
              heading 
              subheading
              copy
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_ImageAndCopyWithLogos {
              heading 
              logosImage {
                mediaItemUrl
              }
              copy
              button {
                title
                url
              }
              image {
                mediaItemUrl
              }
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_FormSection {
              title
              copy
              form {
                ...on WPGraphQL_Form {
                  ...FormFieldsFragment
                }
              }
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_StoriesGrid {
              title
              copy
              image {
                mediaItemUrl
              }
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_BookingCta {
              title
              link {
                title
                url
              }
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_TeamMembers {
              title
              copy
              type
              teamMembers {
                teamMember {
                  ... on WPGraphQL_TeamMember {
                    title
                    featuredImage {
                      mediaItemUrl
                    }
                    teamFields {
                      position
                      bio
                      firefighter
                      defence
                    }
                  }
                }
              }
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_SingleCopy {
              copy
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_SingleQuote {
              quote
              authorRole
              author
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_SingleVideo {
              videoUpload {
                mediaItemUrl
              }
              videoUrl
              videoCaption
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_Testimonials {
              title
              testimonials {
                name
                company
                logo {
                  mediaItemUrl
                }
                copy
                link {
                  title
                  url
                }
                image {
                  mediaItemUrl
                }
              }
              hide
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_Stats {
              title
              stats {
                title
                copy
              }
              hide
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_EnvironmentsGrid {
              title
              copy
            }
          }
        }
      }
    }
  }
`

// subhero 
// - - alignment

// two column image and text
// button {
//   title
//   url
// }