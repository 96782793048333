import React from 'react'
import Button from 'components/button'

import { nl2br } from 'utils'

export default ({
    heading,
    logosImage,
    copy,
    button,
    image,
}) => {
    return (
        <div className="container">
            <div className="row">
                <div className="column">
                    <h2>
                        {heading}
                    </h2>
                </div>
                <div className="column">
                    <img src={logosImage.mediaItemUrl} alt={heading} />
                </div>
            </div>
            <div className="row">
                <div className="column">
                    <p dangerouslySetInnerHTML={{ __html: nl2br(copy) }} />
                    { button ?
                        <Button 
                            label={button.title}
                            url={button.url}
                        />
                    : '' }
                </div>
                <div 
                    className="column" 
                    style={{backgroundImage: `url(${ image.mediaItemUrl })`}}
                />
            </div>
        </div>
    )
}