import React, { useState, useRef, useEffect } from 'react'
import FadeCollapse from 'src/components/fadeCollapse'
import { isMobile } from 'utils'
import Flickity from 'react-flickity-component'

export default ({
    points,
    headset
}) => {
    const [activeIndex, setActiveIndex] = useState(-1)
    const sliderRef = useRef(null)
    const activeIndexRef = useRef(activeIndex)
    const timeoutRef = useRef(null)

    activeIndexRef.current = activeIndex

    useEffect(() => {
        if(sliderRef.current){
            sliderRef.current.on('change', index => {
                setActiveIndex(index)
            })
        }

        changeSlide(0)
    }, [sliderRef])

    const changeSlide = index => {
        clearTimeout(timeoutRef.current)

        setActiveIndex(index)

        if(!isMobile){
            timeoutRef.current = setTimeout(() => {
                changeSlide(points[(index + 1)] ? index + 1 : 0)
            },5000)
        }
    }

    const pointsJsx = points.map((point, index) => {
        const {
            title,
            copy,
            icon
        } = point

        const isActive = isMobile || index <= activeIndex
        const isCurrent = isMobile || index === activeIndex
        const isPast = isMobile || index < activeIndex

        return (
            <li
                key={index}
                className={`${isActive ? 'active' : ''} ${ isPast ? 'past' : ''}`}
                onClick={() => changeSlide(index)}
            >
                { !isMobile &&
                    <span className={`progress ${ isCurrent ? 'active' : '' }`} />
                }
                <div className="icon">
                    <img src={icon.sourceUrl} />
                </div>
                <div className="content">
                    <h4>
                        {title}
                    </h4>
                    <FadeCollapse open={isCurrent}>
                        <div className="copy" dangerouslySetInnerHTML={{ __html: copy }} />
                    </FadeCollapse>
                </div>
            </li>
        )
    })

    return (
        <div className="container">
            <ul className="left">
                { isMobile ?
                    <Flickity
                        className={`carousel`}
                        options={{
                            wrapAround: true,
                            initalIndex: 1,
                            prevNextButtons: false,
                            pageDots: true,
                            adaptiveHeight: true
                        }}
                        flickityRef={ref => sliderRef.current = ref}
                    >
                        { pointsJsx }
                    </Flickity>
                :
                    pointsJsx
                }
            </ul>
            <div className="right">
                <div>
                    <img className="headset transparent" src={headset.transparent.mediaItemUrl} />
                    <img className={`headset normal ${activeIndex ? 'visible' : ''}`} src={headset.normal.mediaItemUrl} />
                    <ul className="bgs">
                        {points.map((point, index) => (
                            <li
                                key={index}
                                style={{
                                    backgroundImage: `url(${point.background.sourceUrl})`
                                }}
                                className={index === activeIndex ? 'active' : ''}
                            />
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}