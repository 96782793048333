import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from "gatsby"

import Link from 'components/link'
import Switch from 'components/switch'

import icon from 'images/favicon.png'

export default ({
    title,
    copy,
    location,
}) => {
    const environmentQuery = useStaticQuery(graphql`
        query environmentsQuery {
            siteFields {
                environments {
                    nodes {
                        uri
                        title
                        content
                        featuredImage {
                            altText
                            link
                            mediaItemUrl
                            uri
                        }
                        productTaxes {
                            nodes {
                                name
                                slug
                            }
                        }
                    }
                }
                productTaxes {
                    nodes {
                        name
                        slug
                    }
                }
            }
        }
    `)

    const [activeProductTax, setActiveProductTax] = useState('trainer')

    const environments = environmentQuery?.siteFields?.environments?.nodes?.filter((environment) => environment?.productTaxes?.nodes?.some((productTax) => productTax?.slug === activeProductTax))
    const productTaxes = environmentQuery?.siteFields?.productTaxes?.nodes

    const _productTaxes = productTaxes?.sort((a, b) => {
        if (a?.name < b?.name) { return 1 }
        if (a?.name > b?.name) { return -1 }
        return 0
    })

    const switchOptions = _productTaxes?.map((productTax) => {
        return {
            name: productTax?.name,
            slug: productTax?.slug
        }
    })

    useEffect(() => {
        if (location?.search) {
            const type = location?.search?.replace('?type=', '')

            setActiveProductTax(type)
        }

    }, [location])

    const handleSetActiveProductTax = (type) => {
        setActiveProductTax(type)
        if (window) {
            if (type === 'trainer') {
                window.history.pushState('page2', 'Title', `/environments`)
                return
            } else {
                window.history.pushState('page2', 'Title', `/environments?type=${type}`)
            }
        }
    }

    return (
        <div className='EnvironmentsGrid'>
            <div className='container'>
                <div className='EnvironmentsGrid-intro'>
                    <h1 dangerouslySetInnerHTML={{ __html: title }} />
                    <div
                        className='copy medium'
                        dangerouslySetInnerHTML={{ __html: copy }}
                    />
                </div>
                <div className='EnvironmentsGrid-switch'>
                    <Switch
                        options={switchOptions}
                        handleChange={handleSetActiveProductTax}
                        activeSlug={activeProductTax}
                    />
                </div>
                { environments &&
                    <ul>
                        { environments?.map((environment) => {
                            const {
                                uri,
                                title,
                                content,
                                featuredImage,
                            } = environment

                            return (
                                <li key={uri}>
                                    <Link to={uri}>
                                        <div className={ featuredImage ? 'EnvironmentsGrid-image' : 'EnvironmentsGrid-image no-image' }>
                                            { featuredImage ?
                                                <img
                                                    src={featuredImage?.mediaItemUrl}
                                                    alt={featuredImage?.altText}
                                                />
                                            :
                                                <img
                                                    src={icon}
                                                    alt={title}
                                                />
                                            }
                                        </div>
                                        <h3 dangerouslySetInnerHTML={{ __html: title }} />
                                        <div
                                            className='copy small'
                                            dangerouslySetInnerHTML={{ __html: content }}
                                        />
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>
                }
            </div>
        </div>
    )
}
