import React from 'react'
import Button from 'components/button'

export default ({
    copy,
    button,
    alignment,
}) => {

    const contClass = `Subhero-cont ${ alignment && ' Subhero-' + alignment }`

    return (
        <div className={ contClass }>
            <div className="container">
                <h2 dangerouslySetInnerHTML={{ __html: copy }} />
                { button &&
                    <Button 
                        label={button.title}
                        url={button.url}
                    />
                }
            </div>
        </div>
    )
}