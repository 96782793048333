import React, { useState, useRef, useEffect } from 'react'

import Slider from 'components/slider'
import Link from 'components/link'

export default ({
    title,
    testimonials,
}) => {
    const [activeSlide, setActiveSlide] = useState(1)

    const sliderRef = useRef(null)

    useEffect(() => {
        if(sliderRef && sliderRef.current) {
            sliderRef.current.on('change', index => {
                setActiveSlide(index + 1)
            })
        }
    }, [sliderRef])

    return (
        <div className={`container`}>
            <h4 dangerouslySetInnerHTML={{ __html: title }} />
            { testimonials &&
                <div className="Testimonials-slider">
                    <div className="Testimonials-slider-progress">
                        { activeSlide &&
                            <div>
                                {activeSlide} / {testimonials.length}
                            </div>
                        }
                    </div>
                    <Slider
                        options={{
                            wrapAround: true,
                            pageDots: false,
                            autoPlay: 4000,
                            adaptiveHeight: true,
                        }}
                        flickityRef={ref => sliderRef.current = ref}
                    >
                        { testimonials.map((testimonial, index) => {
                            const {
                                name,
                                company,
                                logo,
                                copy,
                                link,
                                image,
                            } = testimonial

                            return (
                                <div key={index} className="Testimonials-slide">
                                    {image &&
                                        <div className="Testimonials-slide-image" style={{ backgroundImage: `url(${image.mediaItemUrl})` }}/>                                   
                                    }
                                    <div className="Testimonials-slide-content">
                                        <div className="Testimonials-slide-heading">
                                            { logo &&
                                                <div className="Testimonials-slide-heading-logo">
                                                    <img src={ logo.mediaItemUrl } />
                                                </div>
                                            }
                                            <div className="Testimonials-slide-heading-details">
                                                <h5 dangerouslySetInnerHTML={{ __html: name }} />
                                                <h5 dangerouslySetInnerHTML={{ __html: company }} />
                                            </div>
                                        </div>
                                        <div className="Testimonials-slide-copy" dangerouslySetInnerHTML={{ __html: copy }} />
                                        { link &&
                                            <Link
                                                to={link.url}
                                            >
                                                {link.title}
                                            </Link>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            }
        </div>
    )
}
