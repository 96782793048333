import React, { useState } from 'react'
import FadeCollapse from 'src/components/fadeCollapse'

import { cloneDeep } from 'lodash'
import { nl2br } from 'utils'

export default ({
    answersVisible = true,
    columns
}) => {
    const [openQuestions, setOpenQuestions] = useState([])

    const handleClick = openKey => {
        const _openQuestions = cloneDeep(openQuestions)

        const indexIfExists = _openQuestions.indexOf(openKey)

        if(indexIfExists === -1){
            _openQuestions.push(openKey)
        }else{
            _openQuestions.splice(indexIfExists, 1)
        }

        setOpenQuestions(_openQuestions)
    }

    return (
        <div className={`questions-container container columns-${ columns.length } ${ answersVisible ? 'visible' : ''}`}>
            <ul className="columns">
                { columns.map((column, columnIndex) => (
                    <li className="column" key={columnIndex}>
                        <ul className="questions">
                            {column.map((_question, questionIndex) => {
                                const {
                                    question,
                                    answer
                                } = _question

                                const openKey = columnIndex+'.'+questionIndex
                                const isOpen = openQuestions.indexOf(openKey) !== -1

                                return (
                                    <li 
                                        className={`question ${ isOpen ? 'open' : ''}`}
                                        key={questionIndex}
                                    >
                                        <a onClick={() => {handleClick(openKey)}}>
                                            { question }
                                            <div className="plus-minus" />
                                        </a>
                                        <FadeCollapse open={isOpen}>
                                            <div 
                                                className="copy" 
                                                dangerouslySetInnerHTML={{ __html: nl2br(answer) }} 
                                            />
                                        </FadeCollapse>
                                    </li>
                                )
                            })}
                        </ul>
                    </li>
                ))}
            </ul>
        </div>
    )
}