import React, { useContext } from 'react'
import Button from 'components/button'
import { MainContext } from "context/context"

export default ({
    button,
    copy,
    heading,
    gridItems
}) => {    
	const {
        options: {
            dotsBackground
        }
    } = useContext(MainContext)

    return (
        <>
            { dotsBackground &&
                <img src={dotsBackground.sourceUrl} className="bg-dots" />
            }
            <div className="container">
                <div className="grid">
                    <ul className="images">
                        {gridItems.map((gridItem, index) => {
                            return (
                                <li 
                                    key={index} 
                                    style={{
                                        backgroundImage: `url(${gridItem.image ? gridItem.image.sourceUrl : ''})`,
                                        transitionDelay: (index/10)+'s'
                                    }}
                                >
                                    <h4>
                                        {gridItem.label}
                                    </h4>
                                </li>
                            )
                        })}
                    </ul>
                    <div className="content">
                        <h2 dangerouslySetInnerHTML={{ __html: heading }} />
                        <p dangerouslySetInnerHTML={{ __html: copy }} />
                        <div className="button-container">
                            <Button 
                                label={button.title}
                                url={button.url}
                                inverted
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}