import React from 'react'
import CountUp from 'react-countup'

export default ({
    title,
    stats,
    isScrolledIntoView,
}) => {
    return (
        <div className={`container`}>
            <h2 dangerouslySetInnerHTML={{ __html: title }} />
            { stats &&
                <ul>
                    {stats.map((stat, index) => {
                        const {
                            title,
                            copy,
                        } = stat

                        return (
                            <li key={index}>
                                <div>
                                    <h1>
                                        <CountUp
                                            end={ title }
                                            redraw={ isScrolledIntoView }
                                            separator=","
                                        />
                                    </h1>
                                    <h4 dangerouslySetInnerHTML={{ __html: copy }} />
                                </div>
                            </li>
                        )
                    })}
                </ul>
            }
        </div>
    )
}
