import React from 'react'
import Button from 'components/button'
import ParallaxSection from 'components/parallaxSection'

export default ({
    heading,
    button,
    backgroundImage,
}) => {
    return (
        <div className="background-image">
            <ParallaxSection backgroundUrl={backgroundImage.mediaItemUrl}>
                <div className="container">
                    <h2>
                        {heading}
                    </h2>
                    <Button 
                        inverted="true"
                        label={button.title}
                        url={button.url}
                    />
                </div>
            </ParallaxSection>
        </div>
    )
}