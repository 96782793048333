import React, { useContext } from 'react'

import { MainContext } from "../context/context"

export default ({
    title,
    copy,
    type,
    teamMembers,
}) => {
    const {
        openModal,
    } = useContext(MainContext)

    const contClass = `TeamMembers-cont ${ type && ' TeamMembers-' + type }`

    const handleModal = data => {
        
        openModal({
            type: 'TEAM_MEMBER',
            data: {
                title: data.title,
                position: data.teamFields.position,
                bio: data.teamFields.bio,
                image: data.featuredImage ? data.featuredImage.mediaItemUrl : '',
                firefighter: data.teamFields.firefighter ? true : false,
                defence: data.teamFields.defence ? true : false,
            }
        })

    }

    return (
        <div className={ contClass }>
            <div className="container">
                <h2 dangerouslySetInnerHTML={{ __html: title }} />
                <div dangerouslySetInnerHTML={{ __html: copy }} />
                { teamMembers &&
                    <ul>
                        { teamMembers.map((teamMember, index) => {
                            if(!teamMember.teamMember){
                                return null 
                            }
                            
                            const {
                                title,
                                featuredImage,
                                teamFields,
                            } = teamMember.teamMember
                            
                            const {
                                firefighter,
                                defence,
                            } = teamFields

                            return (
                                <li 
                                    key={index}
                                    onClick={ () => handleModal(teamMember.teamMember) }
                                    className={`${ firefighter ? 'TeamMembers-firefighter' : '' } ${defence ? 'TeamMembers-defence' : ''}`}
                                >
                                    <div className={ featuredImage ? "TeamMembers-image" : "TeamMembers-image noImage" }>
                                        <div style={{ backgroundImage: `url(${ featuredImage && featuredImage.mediaItemUrl })` }} />
                                    </div>
                                    <div className="TeamMembers-name" dangerouslySetInnerHTML={{ __html: title }} />
                                </li>
                            )
                        })}
                    </ul>
                }
            </div>
        </div>
    )
}