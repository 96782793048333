import React, { useContext } from 'react'
import Button from 'components/button'
import { MainContext } from "context/context"

export default ({
    title,
    copy,
    featureImage,
    overlapFollowingComponent,
    button,
    dontGhostFeatureImage
}) => {
	const {
        options
    } = useContext(MainContext)

    const {
        dotsBackground
    } = options

    return (
        <div 
            style={{
                backgroundImage: `url(${ dotsBackground.sourceUrl })`
            }}
            className={overlapFollowingComponent ? 'overlap-following' : ''}
        >
            <div className="container">
                <div className="left">
                    <h2 className="h1">
                        { title }
                    </h2>
                    <div className="copy big" dangerouslySetInnerHTML={{ __html: copy }} />
                </div>
                <div className={`right ${ dontGhostFeatureImage ? 'no-ghost' : '' }`}>
                    { button ?
                        <Button
                            label={button.title}
                            url={button.url}
                        />
                    : null }
                    { featureImage ?
                        <img src={featureImage.sourceUrl} />    
                    : null }
                </div>
            </div>
        </div>
    )
}