import React, { useRef, useEffect } from 'react'
import Slider from 'components/slider'
import { useStaticQuery, graphql } from "gatsby"

import { isMobile } from 'utils'

export default ({
    title,
    greyOutLogos,
    isScrolledIntoView,
    logos: flexibleContentLogos
}) => {
    const clientLogos = useStaticQuery(graphql`
        query clientLogos {
            siteFields {
                options {
                    options {
                        clientLogos {
                            image {
                                mediaItemUrl
                                mediaDetails {
                                  sizes {
                                    name
                                    sourceUrl
                                  }
                                }
                            }
                            url
                            label
                        }
                    }
                }
            }
        }
    `)

    const logos = flexibleContentLogos || clientLogos.siteFields.options.options.clientLogos

    const _logos = logos.filter(logo => logo.image)
    const sliderRefs = useRef([])

    const isInSlider = _logos.length > 5

    const pauseOrPlaySlider = operation => {
        if(sliderRefs.current && sliderRefs.current.length){
            if(operation === 'start'){
                sliderRefs.current.forEach(ref => {
                    ref.unpausePlayer()
                })
            }else{
                sliderRefs.current.forEach(ref => {
                    ref.pausePlayer()
                })
            }
        }
    }

    useEffect(() => {
        setTimeout(() => {
            if(sliderRefs.current.length){
                sliderRefs.current.forEach(slider => {
                    slider.resize()
                })
            }
        },1500)

        setTimeout(() => {
            if(sliderRefs.current.length){
                sliderRefs.current.forEach(slider => {
                    slider.resize()
                })
            }
        },3000)
    },[])

    useEffect(() => {
        pauseOrPlaySlider('stop')
    }, [sliderRefs])

    useEffect(() => {
        if(isScrolledIntoView){
            pauseOrPlaySlider('start')
        }else{
            pauseOrPlaySlider('stop')
        }

    }, [isScrolledIntoView])

    const _logosJsx = [[],[]]

    // If there's enough for 2 rows of 3, then split them on mobile.
    _logos.forEach((logo, index) => {
        let split = (isMobile && _logos.length >= 6) ? Math.ceil(_logos.length / 2) : 999

        if(split < 3){
            split = 3
        }

        const arrayKey = index < split ? 0 : 1

        const {
            image,
            label
        } = logo

        const imageUrl = (() => {
            let url = null 

            if(image?.mediaDetails?.sizes?.length){
                image.mediaDetails.sizes.forEach(size => {
                    if(size.name === 'medium'){
                        url = size.sourceUrl
                    }
                })
            }

            if(!url){
                return image.mediaItemUrl || image.sourceUrl
            }

            return url
        })()

        _logosJsx[arrayKey].push((
            <li key={index}>
                <div 
                    className="inner"
                    style={{
                        transitionDelay: !isInSlider ? (index/10)+'s' : undefined
                    }}
                >
                    <div>
                        <img src={imageUrl} />
                    </div>
                    { label ?
                        <h4>
                            { label }
                        </h4>
                    : null }
                </div>
            </li>
        ))
    })

    return (
        <div className={`container ${ greyOutLogos ? 'grey-out' : '' }`}>
            <h4>
                { title }
            </h4>
            <ul>
                { !isInSlider ?
                    _logosJsx[0]
                :
                    <Slider
                        options={{
                            wrapAround: true,
                            prevNextButtons: false,
                            pageDots: false,
                            adaptiveHeight: false,
                            cellAlign: 'center',
                            autoPlay: 2000,
                        }}  
                        flickityRef={ref => sliderRefs.current[0] = ref}
                    >
                        { _logosJsx[0] }
                    </Slider>
                }
                { (isMobile && isInSlider) ?
                    <Slider
                        options={{
                            wrapAround: true,
                            prevNextButtons: false,
                            pageDots: false,
                            adaptiveHeight: false,
                            cellAlign: 'center',
                            autoPlay: 2000,
                            rightToLeft: true
                        }}  
                        flickityRef={ref => sliderRefs.current[1] = ref}
                    >
                        { _logosJsx[1] }
                    </Slider>                
                : null }
            </ul>
        </div>
    )
}