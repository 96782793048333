import React from 'react'

export default ({
    options,
    activeSlug,
    handleChange
}) => {
    return (
        <div className="switch">
            <ul>
                { options && options.map((option, index) => {
                    return (
                        <li 
                            key={index}
                            className={activeSlug === option.slug ? 'active' : ''}
                        >
                            <button onClick={() => handleChange(option.slug)}>
                                { option.name }
                            </button>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}