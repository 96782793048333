import React, { useContext, useRef, useEffect, useState } from 'react'

import Button from 'components/button'
import { MainContext } from "context/context"
import ParallaxSection from 'components/parallaxSection'
import Slider from 'components/slider'

export default ({
    backgroundColour,
    backgroundVideo,
    popUpVideo,
    button,
    featureImage,
    backgroundImage,
    backgroundImages,
    title,
    subtitle,
    alignment,
    height
}) => {
	const {
        addInvertedHeaderSection,
        options,
        openModal
    } = useContext(MainContext)

    const elementRef = useRef(null)
    const [arrowVisible, setArrowVisible] = useState(false)
    
    useEffect(() => {
        if(elementRef && elementRef.current){
            addInvertedHeaderSection(elementRef.current)

            if(typeof window !== 'undefined'){
                const viewportHeight = window.innerHeight
                const heroHeight = elementRef.current.getBoundingClientRect().height

                const heightDeficit = viewportHeight - heroHeight

                if(heightDeficit <= 60){
                    setArrowVisible(true)
                }
            }
        }
    }, [elementRef])

    let className = ''

    if(alignment == 'left') {
        className += 'align-left'
    } else {
        className += 'align-center'
    }

    if(height == 'short') {
        className += ' height-short'
    } else {
        className += ' height-tall'
    }

    const {
        heroBottomDivider
    } = options

    const handleVideoModal = () => {
        openModal({
            type: 'VIDEO',
            data: popUpVideo.mediaItemUrl
        })
    }

    const _backgroundImages = backgroundImages ? backgroundImages.concat(backgroundImages) : null
    
    return (
        <div 
            className = {className}
            style={{
                backgroundColor: backgroundColour,
            }}
            ref={ref => elementRef.current = ref}
        >
            <ParallaxSection backgroundUrl={backgroundImage ? backgroundImage.sourceUrl : ''}>
                { _backgroundImages &&
                    <div className="Hero-slider">
                        <Slider
                            options={{
                                wrapAround: true,
                                prevNextButtons: false,
                                pageDots: false,
                                adaptiveHeight: false,
                                cellAlign: 'center',
                                autoPlay: 4000,
                                pauseAutoPlayOnHover: false,
                                fade: true
                            }}
                        >
                            { _backgroundImages.map((image, index) => {
                                return (
                                    <div key={index}>
                                        <div
                                            className='Hero-slider-image'
                                            style={{
                                                backgroundImage: `url(${ image.sourceUrl })`
                                            }}
                                        />
                                    </div>
                                )
                            })}
                        </Slider>
                    </div>
                }
                { backgroundVideo && backgroundVideo.mediaItemUrl ?
                    <div className="background-video">
                        <video playsInline autoPlay muted loop>
                            <source src={backgroundVideo.mediaItemUrl} type='video/mp4' />
                        </video>
                    </div>
                : null }
                <div className="container">
                    <div className="left">
                        <h1 dangerouslySetInnerHTML={{ __html: title }} />
                        {subtitle  &&
                            <h3>
                                {subtitle}
                            </h3>
                        }
                        {(button || popUpVideo) &&
                            <div>
                                <Button
                                    label={popUpVideo ? 'Play video' : button.title}
                                    url={popUpVideo ? undefined : button.url}
                                    inverted={true}
                                    onClick={popUpVideo ? handleVideoModal : undefined}
                                />
                            </div>
                        }
                    </div>
                    { featureImage ?
                        <div className="feature-image">
                            <img src={featureImage.sourceUrl} />                    
                        </div>
                    : null }
                </div>
                { heroBottomDivider ?
                    <div className="bottom-overlay">
                        <img src={heroBottomDivider.sourceUrl} />
                    </div>
                : null }
                { arrowVisible ?
                    <div className="down-arrow">
                        <i className="fe fe-chevron-down" />
                    </div>
                : null }
            </ParallaxSection>
        </div>
    )
}