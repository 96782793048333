import React, {useContext} from 'react'
import { MainContext } from "context/context"

import ContactBackground from '../images/contact-background.png'

export default ({ 
    image, 
    heading, 
    copy, 
    facebookLink, 
    youtubeLink 
}) => {
    const {
		options: {
            socialLinks
        }
    } = useContext(MainContext)

	return (
        <>
            <img src={ContactBackground} className="bg" />
            <div className="wrapper">
                <img src={image.sourceUrl}/>
                <h2>
                    {heading}
                </h2>
                <p>
                    {copy}
                </p>
                <ul>
                    { socialLinks.map(link => {
                        const {
                            type,
                            url
                        } = link

                        return (
                            <li key={url}>
                                <a href={url} target="_blank" rel="noopener noreferrer">
                                    <i className={`fa fa-${ type }`} />
                                </a>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </>
  	)
}