import React, { useState, useContext } from 'react'

import Form from 'components/form'
import { SUBMIT_FORM } from '../graphql/queries'
import { useMutation } from '@apollo/client'
import { MainContext } from "../context/context"

export default ({
    title,
    copy,
    form
}) => {
    const {
        openModal,
        options: {
            copy: successCopy = {}
        }
    } = useContext(MainContext)

    const {
        contactFormSuccessTitle,
        contactFormSuccessCopy
    } = successCopy

    const [submitForm, { loading, data: formData, error }] = useMutation(SUBMIT_FORM)
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = data => {
        const json = JSON.stringify(data)
        setIsLoading(true)

        submitForm({
            variables: {
                type: 'Contact',
                data: json
            },
        }).then(response => {
            setIsLoading(false)

            openModal({
                type: 'CONTACT_CONFIRM',
                data: {
                    title: contactFormSuccessTitle,
                    body: contactFormSuccessCopy
                }
            })
        }).catch(error => {
            console.log(error)

            setIsLoading(false)
        })
    }

    return (
        <div className={'container'}>
            <div className={'left'}>
                <h2 dangerouslySetInnerHTML={{ __html: title }} />
                <div className={'copy'} dangerouslySetInnerHTML={{ __html: copy }} />
            </div>
            <div className={'right'}>
                <Form
                    form={form}
                    handleSubmit={handleSubmit}
                    isLoading={isLoading}
                />
            </div>
        </div>
    )
}