import React from 'react'
import Link from 'components/link'


export default ({
    items
}) => {
    return (
        <div className='single-stories'>
            <ul>
                {items.map((story, index) => {
                    const {
                        featuredImage,
                        industries,
                        stories_fields,
                        title,
                        link
                    } = story

                    return (
                        <Link to={link} key={index}>
                            <li>
                                <div className="image-cont">
                                    {featuredImage &&
                                    <img src={featuredImage.mediaItemUrl} alt="story featured image"/>
                                    }
                                    {stories_fields.companyLogo &&
                                        <div className="logo" style={{ backgroundImage: `url(${stories_fields.companyLogo.mediaItemUrl})` }}/>                                   
                                    }
                                </div>
                                <section>
                                    <h5 dangerouslySetInnerHTML={{__html: stories_fields && stories_fields.company}} />
                                    <h5 className='industry' dangerouslySetInnerHTML={{__html: industries.nodes[0] && industries.nodes[0].name.toUpperCase()}} />
                                    <h4 dangerouslySetInnerHTML={{__html: title && title}} />
                                </section>
                                <button>READ STORY</button>
                            </li>
                        </Link>
                    )
                })}
            </ul>
        </div>
    )
}
