import React, { useState, useRef, useContext, useEffect } from 'react'
import { MainContext } from "context/context"
import ResourcesHero from 'components/resourcesHero'

import Button from 'components/button'

import { isMobile } from 'utils'

import Slider from 'components/slider'

import { forIn } from 'lodash'

export default ({
    tabs, 
    slides, 
    heading, 
    copy,
    button
}) => {

	const {
        options: {
            arrows
        }
    } = useContext(MainContext)

    const buttonHandler = command => {
        if(command == 'right') {
            sliderRef.current.next()
        } else {
            sliderRef.current.previous()
        }
    } 

    const handleChangeSwitch = slug => {
        setDisplayedActiveTab(slug)

        setContentVisible(false)

        setTimeout(() => {
            setActiveTab(slug)

            setTimeout(() => {
                setActiveSlide(0)
                setContentVisible(true)
                checkForVideoOnActiveslide(0, slug)
            },300)
        },300)
    }

    const [activeTab, setActiveTab] = useState(tabs ? tabs[0].tabName : '')
    const [displayedActiveTab, setDisplayedActiveTab] = useState(activeTab)
    const [contentVisible, setContentVisible] = useState(true)
    const [activeSlide, setActiveSlide] = useState(0)

    const sliderRef = useRef(null)
    const videoRefs = useRef([])

    let currentSlides = slides

    if(tabs){
        currentSlides = tabs.find(tab => tab.tabName === activeTab).slides
    }

    const checkForVideoOnActiveslide = (index, slug = activeTab) => {
        const currentKey = slug+index

        forIn(videoRefs.current, (video, key) => {
            if(video){
                if(key === currentKey){
                    video.play()
                }else{
                    video.pause()
                }
            }
        })
    }

    useEffect(() => {
        if(sliderRef.current){
            sliderRef.current.on('change', index => {
                checkForVideoOnActiveslide(index)
                setActiveSlide(index)
            })
        }
    }, [sliderRef, activeTab])

    useEffect(() => {
        setTimeout(() => {
            checkForVideoOnActiveslide(0)
        },300)
    }, [])

    const nextSlideIndex = currentSlides[activeSlide + 1] ? activeSlide + 1 : 0
    const prevSlideIndex = currentSlides[activeSlide - 1] ? activeSlide - 1 : currentSlides.length - 1

    return (
        <>
            <ResourcesHero
                isResources={true}
                backgroundImage={''}
                switchOptions={tabs && tabs.map(({ tabName }) => {
                    return {
                        name: tabName,
                        slug: tabName
                    }
                })}
                activeSwitch={displayedActiveTab}
                handleChangeSwitch={handleChangeSwitch}
                title={heading}
                subheading={copy}
            />
            <div className={`showcase-container ${ contentVisible ? 'visible' : '' }`}>
                <div className="slider-container">
                    {typeof window !== 'undefined' &&
                        <Slider
                            className={`carousel prev-${ prevSlideIndex } next-${ nextSlideIndex }`}
                            options={{
                                wrapAround: true,
                                initalIndex: 1,
                                prevNextButtons: false,
                                pageDots: isMobile,
                                adaptiveHeight: isMobile
                            }}
                            flickityRef={ref => sliderRef.current = ref}
                            key={activeTab}
                        >
                            { currentSlides.map((slide, index) => {
                                const {
                                    title,
                                    copy,
                                    image,
                                    video
                                } = slide

                                const imageUrl = (() => {
                                    let url = null

                                    if(image?.mediaDetails?.sizes?.length){
                                        image.mediaDetails.sizes.forEach(size => {
                                            if(size.name === 'large'){
                                                url = size.sourceUrl
                                            }
                                        })
                                    }

                                    if(!url && image){
                                        console.log('test')
                                        console.log(image)
                                        
                                        url = image.sourceUrl
                                    }

                                    return url
                                })()

                                const backgroundUrl = imageUrl

                                if(!video){
                                    videoRefs.current[activeTab+index] = null
                                }

                                return (
                                    <li key={activeTab+index}>
                                        <div
                                            className="column image"
                                            style={{
                                            backgroundImage: `url(${ backgroundUrl })`
                                        }}>
                                            { video &&
                                                <video ref={ref => videoRefs.current[activeTab+index] = ref} playsInline muted loop>
                                                    <source src={video.mediaItemUrl} type='video/mp4' />
                                                </video>
                                            }
                                        </div>
                                        <div className="column">
                                            <h3>
                                                { title }
                                            </h3>
                                            <p>
                                                { copy }
                                            </p>
                                        </div>
                                    </li>
                                )
                            })}    
                        </Slider>
                    }
                </div>
                { !isMobile &&
                    <div className="buttons-container">
                        { ['left','right'].map(direction => (
                            <button 
                                key={direction} 
                                onClick={() => buttonHandler(direction)}
                                className={`arrow ${ direction }`}
                            >
                                <img src={arrows[direction].sourceUrl} />
                            </button>
                        ))}
                    </div>
                }
            </div>
            { button &&
                <div className="showcase-lower">
                    <Button
                        label={button.title}
                        url={button.url}
                    />
                </div>
            }
        </>
    )

}
