import React, { useContext } from 'react'
import playCircle from "../images/play-circle.png"
import ParallaxSection from 'components/parallaxSection'

import { MainContext } from "context/context"

export default ({
    heading, 
    video,
    backgroundImage,
    textAlign
}) => {
	const {
        openModal
    } = useContext(MainContext)

    const handleVideoModal = () => {
        openModal({
            type: 'VIDEO',
            data: video.mediaItemUrl
        })
    }

    const videoContainerClass = "video-container"

    return (
        <ParallaxSection backgroundUrl={backgroundImage.sourceUrl}>
            <div className={videoContainerClass}>
                {heading &&
                    <div className="container">
                        <div className={`overlay ${textAlign}`}>
                            <a className="placeholder" onClick={handleVideoModal}>
                                <div className="grid">
                                    <div className="column">
                                        <div className="content">
                                            <h2>
                                                {heading}
                                            </h2>
                                            {textAlign == 'center' ?
                                                <img 
                                                    alt=""
                                                    src={playCircle}
                                                />
                                            : 
                                                <div className="row">
                                                    <div className="play-circle">
                                                        <div className="icon">
                                                        </div>
                                                    </div>
                                                    <span>
                                                        Play Video
                                                    </span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="column" />
                                </div>
                            </a>
                        </div>
                    </div>
                }
            </div>
        </ParallaxSection>
    )
}