import React from 'react'

export default ({
    title,
    copy
}) => {
    return (
        <div className="container">
            <div className="left">
                <h2 dangerouslySetInnerHTML={{ __html: title }}/>
            </div>
            <div className="right">
                <div className="copy big" dangerouslySetInnerHTML={{ __html: copy }} /> 
            </div>
        </div>
    )
}