import React from 'react'

export default ({
    columns,
    backgroundColour
}) => {
    return (
        <div className={`${ backgroundColour || '' }`}>
            <div className={`container`}>
                <ul>
                    {columns.map((column, index) => {
                        return (
                            <li key={index}>
                                <h4>
                                    {column.heading}
                                </h4>
                                <p>
                                    {column.copy}
                                </p>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}