import React, { useRef, useContext, useEffect, useState } from 'react'
import Flickity from 'react-flickity-component'
import 'vendor/flickity-as-nav-for/as-nav-for'
import 'flickity/css/flickity.css'
import { MainContext } from "context/context"

import { isMobile } from 'utils'

export default ({copy, quotes, hideComponent}) => {
    const {
        options: {
            arrows
        }
    } = useContext(MainContext)

    const [isProgressBarVisible, setIsProgressBarVisible] = useState(false)
    const isProgressBarVisibleRef = useRef(isProgressBarVisible)

    isProgressBarVisibleRef.current = isProgressBarVisible

    const flickityOptions = {
        wrapAround: true,
        initalIndex: 1,
        prevNextButtons: false,
        pageDots: isMobile,
        adaptiveHeight: true,
        autoPlay: 5000
    }

    const buttonHandler = direction => {
        if(direction === 'right') {
            sliderRef.current.next()
        } else {
            sliderRef.current.previous()
        }
    } 

    const sliderRef = useRef(null)

    const resetProgressBar = () => {
        if(isProgressBarVisibleRef.current){
            setIsProgressBarVisible(false)
        }

        setTimeout(() => {
            setIsProgressBarVisible(true)
        },1)
    }

    useEffect(() => {
        if(sliderRef.current){
            sliderRef.current.on('change', index => {
                resetProgressBar()
            })
        }
    }, [sliderRef])

    useEffect(() => {
        resetProgressBar()
    }, [])

    return (

        <>

            { hideComponent ? '' :

                <div className="container">
                    <div className="row">
                        <div className="column">
                            <h2>
                                {copy}
                            </h2>
                        </div>
                        <div className="column" />
                    </div>
                    <div className="row">
                        <div className="column" />
                        <div className="column">
                            <div>
                                { typeof window != 'undefined' &&
                                    <Flickity
                                        className={'carousel'}
                                        options={flickityOptions}
                                        flickityRef={ref => sliderRef.current = ref}
                                    >
                                        {quotes.map((slide, index) => {
                                            const {
                                                image,
                                                copy,
                                                attribution
                                            } = slide

                                            return (
                                                <li key={index}>
                                                    <div className="column">
                                                        { image ?
                                                            <img src={image.sourceUrl} alt={copy.slice(0, 100)} />
                                                        : null }
                                                    </div>
                                                    <div className="column">
                                                        <p>
                                                            { copy }
                                                        </p>
                                                        { attribution ?
                                                            <span className="attribution">
                                                                { attribution }
                                                            </span>
                                                        : null }
                                                    </div>
                                                </li>
                                            )
                                        })}    
                                    </Flickity>
                                }
                            </div>
                            { !isMobile &&
                                <div className="navigation">
                                    <div className="buttons">
                                        { ['left','right'].map(direction => (
                                            <button 
                                                key={direction} 
                                                onClick={() => buttonHandler(direction)}
                                                className={`arrow ${ direction }`}
                                            >
                                                <img src={arrows[direction].sourceUrl} alt={ direction } />
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            }
                            <div className={`progress-bar ${ isProgressBarVisible ? 'visible' : ''}`}/>
                        </div>
                    </div>
                </div>

            }

        </>
    )
}