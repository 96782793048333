import React from 'react'
import Link from 'components/link'
import { isMobile } from 'utils'
import Slider from 'components/slider'

export default ({
    title,
    tiles
}) => {
    const tilesJsx = tiles.map((tile, index) => {
        const {
            title: _title,
            copy,
            centreContent,
            backgroundImage,
            linkedPage
        } = tile

        let className = ''

        if(centreContent){
            className += ' centred-content'
        }

        return (
            <li 
                key={index}
                className={className}
                style={{
                    transitionDelay: (index/10)+'s'
                }}
            >
                <div className="inner">
                    <div 
                        className="bg" 
                        style={{
                            backgroundImage: `url(${ backgroundImage.sourceUrl })`
                        }}
                    />
                    <Link 
                        to={`${ linkedPage.link }`} 
                        className={''}
                    >
                        <div className="content">
                            <h3 dangerouslySetInnerHTML={{ __html: _title }} />
                            <div className={'copy'} dangerouslySetInnerHTML={{ __html: copy }} />
                        </div>
                    </Link>
                </div>
            </li>
        )
    })

    return (
        <div className="container">
            <h2 dangerouslySetInnerHTML={{ __html: title }}/>
            <ul>
                { isMobile ?
                    <Slider
                        options={{
                            wrapAround: true,
                            prevNextButtons: false,
                            pageDots: true,
                            adaptiveHeight: true,
                            cellAlign: 'center',
                        }}
                    >
                        { tilesJsx }
                    </Slider>
                :
                    tilesJsx
                }
            </ul>
        </div>
    )
}