import React, { useContext, useRef, useEffect, useState } from 'react'
import { MainContext } from "context/context"
import Switch from 'components/switch'

export default ({
    backgroundImage,
    title,
    subheading,
    isResources,
    switchOptions,
    activeSwitch,
    handleChangeSwitch
}) => {
	const {
        addInvertedHeaderSection,
    } = useContext(MainContext)

    const elementRef = useRef(null)

    const [hasMounted, setHasMounted] = useState(false)
        
    useEffect(() => {
        if(!isResources && elementRef && elementRef.current){
            addInvertedHeaderSection(elementRef.current)
        }

        setTimeout(() => {
            setHasMounted(true)
        },250)
    }, [elementRef])

    return (
        <section 
            className={`resources-hero ${ isResources ? 'resources' : '' } ${ hasMounted ? 'mounted' : '' }`}
            ref={ref => elementRef.current = ref}
        >
            <div className="bg" style={{
                backgroundImage: `url(${ backgroundImage.sourceUrl })`,
            }}/>
            <div className="content container">
                <h1>
                    { title }
                </h1>
                <h3 
                    dangerouslySetInnerHTML={{ __html: subheading }} 
                    style={{
                        transitionDelay: '0.15s'
                    }}
                />
                { isResources && switchOptions &&
                    <div 
                        className="switch-container"
                        style={{
                            transitionDelay: '0.3s'
                        }}
                    >
                        <Switch 
                            options={switchOptions}
                            handleChange={handleChangeSwitch}
                            activeSlug={activeSwitch}
                        />
                    </div>
                }
            </div>
        </section>
    )
}