import React, { useState, useRef, useEffect } from 'react'
import { useIsMobile } from 'utils'
import Slider from 'components/slider'

export default ({
    diagramImage,
    heading, 
    copy, 
    downloadTabs, 
    features,
    diagramFeatures
}) => {
    const isMobile = useIsMobile()
    const [activeTab, setActiveTab] = useState(0)
    const filesListRef = useRef(null)
    const [listHeight, setListHeight] = useState(0)
    const listHeightRef = useRef(listHeight)

    listHeightRef.current = listHeight

    const updateTab = index => {
        setActiveTab(index)
    }

    const setFilesListHeight = () => {
        if(filesListRef && filesListRef.current){
            const height = filesListRef.current.getBoundingClientRect().height
    
            if(height !== listHeightRef.current){
                setListHeight(height)
            }
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setFilesListHeight()
        },1)
    }, [activeTab])

    const featuresJsx = features.map((feature, index) => {
        return (
            <li key={index}>
                <img src={feature.icon.sourceUrl}/>
                <span>
                    {feature.heading}
                </span>
                <div className="copy" dangerouslySetInnerHTML={{__html: feature.copy }} />
            </li>
        )
    })

    const _diagramImage = diagramImage ? diagramImage.sourceUrl : ''

    return (
        <>
            <div className="container">
                <div className="columns">
                    <div className="column">
                        <h2 dangerouslySetInnerHTML={{ __html: heading }} />
                        <div className="copy" dangerouslySetInnerHTML={{ __html: copy }} />
                        { downloadTabs.length ?
                        <div className="tabs-container">
                            <ul className="tabs">
                                {downloadTabs.map((tab, index) => {
                                    return (
                                        <li className={index == activeTab ? 'active' : null} key={index}>
                                            <a onClick={() => {updateTab(index)}}>
                                                {tab.tabName}
                                            </a>
                                        </li>
                                    )
                                })}
                            </ul>
                            <div 
                                className="files-height-sizer"
                                style={{
                                    height: listHeight ? listHeight+'px' : undefined
                                }}
                            >
                                <ul 
                                    className="files"
                                    ref={ref => {
                                        if(ref){
                                            filesListRef.current = ref
                                        }
                                    }}
                                >
                                    {
                                        downloadTabs[activeTab].files.map((_file, index) => {
                                        const {
                                            file,
                                            title,
                                            externalLink,
                                            alternativeLabel
                                        } = _file

                                        if(!file && !externalLink){
                                            return null
                                        }

                                        let url = file ? file.mediaItemUrl : externalLink
                                        
                                        return (
                                            <li key={index}>
                                                <a 
                                                    href={url}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <span className="title">
                                                        { title || file.title || 'Untitled file' }
                                                    </span>
                                                    <span className="download">
                                                        <i className="fe fe-chevron-right"></i>
                                                        <span>
                                                            { alternativeLabel ? alternativeLabel : 'Download' }
                                                        </span>
                                                    </span>
                                                </a>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                        : null }
                    </div>
                    <div className="column diagram">
                        <div className="diagram-cont">
                            <img src={ _diagramImage } alt={ heading } />
                            <ul>
                                { diagramFeatures && diagramFeatures.map((feature, index) => {
                                    const {
                                        copy,
                                        mobile: {
                                            sameAsDesktop
                                        }
                                    } = feature

                                    const {
                                        alignment,
                                        xOffset,
                                        yOffset,
                                        lineLength,
                                        linePlacement
                                    } = (isMobile && !sameAsDesktop) ? feature.mobile : feature.desktop 

                                    const featureClass = `${ alignment } ${ lineLength } ${ linePlacement }`
                                    
                                    return (
                                        <li 
                                            key={index} 
                                            className={ featureClass } 
                                            style={{ 
                                                top: yOffset + '%', 
                                                left: xOffset + '%' 
                                            }}
                                        >
                                            <span dangerouslySetInnerHTML={{__html: feature.copy }} />
                                            <div 
                                                className={'inner'}
                                                data-feature={ copy } 
                                            >
                                                <div className={'line'} />
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="features">
                    <ul>
                        { isMobile ? 
                            <Slider
                                options={{
                                    wrapAround: false,
                                    prevNextButtons: false,
                                    pageDots: true,
                                    adaptiveHeight: true,
                                    cellAlign: 'center',
                                    imagesLoaded: true
                                }}
                            >
                                { featuresJsx }
                            </Slider>
                        : 
                            featuresJsx
                        }
                    </ul>
                </div>
            </div>
        </>
    )
}
