import React, { useContext } from 'react'
import Button from 'components/button'

import { MainContext } from "context/context"

export default ({
    heading,
    subheading,
    copy,
    button,
    image,
    video,
}) => {
	const {
        openModal
    } = useContext(MainContext)

    const handleVideoModal = () => {
        openModal({
            type: 'VIDEO',
            data: video.mediaItemUrl
        })
    }

    return (
        <div className="container">
            <div className="column" style={{backgroundImage: `url(${ image.mediaItemUrl })`}}>
            </div>
            <div className="column">
                <div className="content">
                    <div className="row">    
                        <h2 dangerouslySetInnerHTML={{ __html: heading }} />
                        <span dangerouslySetInnerHTML={{ __html: subheading }} />
                    </div>
                    <div className="copy" dangerouslySetInnerHTML={{__html: copy }}/>
                    { button ?
                        <Button 
                            label={ button.title }
                            url={ button.url }
                        />
                    :
                        (video && video.mediaItemUrl) ?
                            <Button 
                                label={'View video'}
                                onClick={handleVideoModal}
                            />
                        : null
                    }
                </div>
            </div>
        </div>
    )
}