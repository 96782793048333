import React from "react"
import BlogGridFilter from "components/blogGridFilter"
import BlogGridPost from "components/blogGridPost"
import { useStaticQuery, graphql } from "gatsby"
import Button from 'components/button'
import Slider from 'components/slider'
import { isMobile } from 'utils'

export default ({ 
    postType,
    title,
    category
}) => {
    const data = useStaticQuery(graphql`
        query reourcesQuery {
            siteFields {
                posts (
                    first: 3
                ) {
                    edges {
                        node {
                            link
                            title
                            date
                            featuredImage {
                                mediaItemUrl
                            } 
                            postFields {
                                excerpt
                                heroImage {
                                    sourceUrl
                                }
                            }
                            resourceFields {
                                excerpt
                                fileOrLink 
                                file {
                                    mediaItemUrl
                                }
                                externalLink
                            }
                        }
                    }
                }
            }
        }
    `)
    
    const posts = data.siteFields.posts.edges

    const postsJsx = posts && posts.map(({ node }, index) => {
        return (
            <BlogGridPost 
                postType={postType} 
                post={node} 
                key={index} 
            />
        )
    })

    return (
        <div className="container">
            <div className='heading-row'>
                <h2>
                    {title || postType}
                </h2>
                <Button 
                    label={'View All'}
                    url={'/'+postType}
                />
            </div>
            <div className="blog-grid visible">
                <ul>
                    { isMobile ?
                        <Slider 
                            options={{
                                wrapAround: true,
                                prevNextButtons: false,
                                pageDots: true,
                                adaptiveHeight: true,
                                cellAlign: 'center',
                            }}
                        >
                            { postsJsx }
                        </Slider>
                    :
                        postsJsx
                    }
                </ul>
            </div>
        </div>
    )
}