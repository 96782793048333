import React, { useState, useContext } from 'react'
import { chunk } from 'lodash'
import { MainContext } from "context/context"
import AccordionList from 'components/accordionList'

export default ({ 
    heading, 
    categories 
}) => {
	const {
        options: {
            heroBottomDivider: {
                sourceUrl: heroBottomDivider
            },
            dotsBackground: {
                sourceUrl: dotsBackground
            }
        }
    } = useContext(MainContext)

    const [activeTab, setActiveTab] = useState(0)
    const [displayedActiveTab, setDisplayedActiveTab] = useState(0)
    const [answersVisible, setAnswersVisible] = useState(true)

    const updateTab = index => {
        setAnswersVisible(false)
        setDisplayedActiveTab(index)

        setTimeout(() => {
            setActiveTab(index)
            setAnswersVisible(true)
        },250)
    }

    const questions = categories[activeTab].questions 

    const _questions = chunk(questions, Math.ceil(questions.length/2))

    return (
        <section className="faqs">
            <div className="hero">
                <h1>
                    {heading}
                </h1>
                <div className="tabs-container">
                    <ul className="tabs">
                        {categories.map((category, index) => {
                            return (
                                <li 
                                    className={displayedActiveTab == index ? 'active' : null} 
                                    key={index}
                                    style={{
                                        transitionDelay: (index/10 + 0.2)+'s'
                                    }}
                                >
                                    <a onClick={() => updateTab(index)}>
                                        <div className="content">
                                            <img 
                                                className="icon" 
                                                src={category.icon.sourceUrl}
                                            />
                                            <span className="name">
                                                {category.name}
                                            </span>
                                        </div>
                                    </a>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <img
                    className="bg"
                    src={dotsBackground}
                />
                <img 
                    className="divider" 
                    src={heroBottomDivider} 
                />
            </div>
            <AccordionList
                answersVisible={answersVisible}
                columns={_questions}
            />
        </section>
    )
}