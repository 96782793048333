import React, { useState } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import SingleStory from './singleStory'

import BlogGridFilter from './blogGridFilter'

export default ({
    title,
    copy,
    image
}) => {

  const [selectedSlug, setSelectedSlug] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const [contentVisible, setContentVisible ] = useState(true)

  const { nodes: stories } = useStaticQuery(graphql`
    query {
      siteFields {
        stories(first: 1000, after: null) {
          nodes {
            featuredImage {
              mediaItemUrl
            }
            title
            link
            stories_fields {
              company
              companyBlurb
              companyWebsite
              stats {
                subTitle
                title
              }
              companyLogo {
                mediaItemUrl
              }
            }
            industries {
              nodes {
                name
                slug
              }
            }
            relatedProducts {
              nodes {
                  slug
                  name
              }
            }
          }
        }
      }
    }
  `).siteFields.stories

  const handleChangeCategory = slug => {
    setContentVisible(false)

    setTimeout(() => {
      setSelectedSlug(slug)
    },200)

    setTimeout(() => {
      setContentVisible(true)
    }, 300)
  }

  const searchPosts = (stories) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase()

    return stories.filter(({
      title,
      excerpt
    }) => {
      const lowerCaseTitle = title.toLowerCase()
      const lowerCaseExcerpt = excerpt ? excerpt.toLowerCase() : ''

      return lowerCaseTitle.includes(lowerCaseSearchTerm) || lowerCaseExcerpt.includes(lowerCaseSearchTerm)
    })
  }
  
  const filteredStories = (() => {
    if (selectedSlug) return stories.filter(story => 
      story.relatedProducts.nodes.some(product => product.slug === selectedSlug)
    )
    if (stories) return stories  
    return []
  })()

  const _stories = (() => {
      if (searchTerm) return searchPosts(filteredStories)
      return filteredStories
  })()

  return (
    <div className={`stories-grid ${contentVisible ? 'visible' : ''}`}>
      <div className="container">
          <header>
              <h1 dangerouslySetInnerHTML={{ __html: title ? title : '' }} />
              <div dangerouslySetInnerHTML={{ __html: copy ? copy : '' }} />
          </header>
          <BlogGridFilter 
            selectedSlug={selectedSlug} 
            handleChange={handleChangeCategory}
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
            postType='stories'
          /> 
          
          {!!_stories.length ?
              <SingleStory items={_stories} />
            :
            <h4>No Results</h4>
          }
      </div>
    </div>
  )
}
